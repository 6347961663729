import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var projectData = 'projectData';
var projectName = 'projectName';
var wsToken = 'ws_token';
var pName = 'pName';
var rsaPublickey = 'rsaPublickey';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  removersaPublickey();
  return Cookies.remove(TokenKey);
}
export function getProject() {
  return Cookies.get(projectData);
}
export function setProject(project) {
  return Cookies.set(projectData, project);
} // 取项目name

export function getProjectName() {
  return Cookies.get(projectName);
} // 存项目name

export function setProjectName(name) {
  return Cookies.set(projectName, name);
} // 清楚项目 name

export function removeProjectName() {
  return Cookies.remove(projectName);
} // 取项目name

export function getpName() {
  return Cookies.get(pName);
} // 存项目name

export function setPName(name) {
  return Cookies.set(pName, name);
} // 清楚项目 name

export function removePName() {
  return Cookies.remove(pName);
} // websocket token

export function getWsToken() {
  return Cookies.get(wsToken);
} // websocket token

export function setWsToken(token) {
  return Cookies.set(wsToken, token);
}
export function setrsaPublickey(key) {
  return window.localStorage.setItem(rsaPublickey, key);
}
export function getrsaPublickey(key) {
  return window.localStorage.getItem(rsaPublickey);
}
export function removersaPublickey() {
  return window.localStorage.removeItem(rsaPublickey);
}