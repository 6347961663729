var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _vm.socketLoading && _vm.isTips
          ? [_vm._m(0)]
          : !_vm.socketConnect && _vm.isTips
          ? [
              _c("div", { staticClass: "read-card-tips" }, [
                _c("span", [
                  _vm._v(" 使用身份证识别器时，请先 "),
                  _c(
                    "a",
                    {
                      staticClass: "read-card-tips-href",
                      attrs: { href: "" + _vm.driveUrl },
                    },
                    [_vm._v("点击此处")]
                  ),
                  _vm._v("下载驱动。 "),
                ]),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "read-card-tips" }, [
      _c("span", [_vm._v("正在连接身份证识别器驱动程序，请稍候...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }