import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Oss from '@/utils/oss';
import moment from 'moment';
import Api from '@/api/register';
export default {
  name: 'Index',
  props: {
    disabled: Boolean,
    limit: {
      type: Number,
      default: 20
    },
    action: {
      type: String,
      default: ''
    },
    fileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    listType: {
      type: String,
      default: 'text'
    },
    text: {
      type: String,
      default: '建议尺寸375*280px，不大于5M.'
    },
    fileType: {
      type: String,
      default: ''
    },
    dataIndex: {
      type: Number,
      default: 0
    },
    dataIndex1: {
      type: Number,
      default: 0
    },
    dataIndex2: {
      type: Number,
      default: 0
    },
    fileSize: {
      type: Number,
      default: 10
    },
    // 是否是报名系统的上传
    isOss: {
      type: Boolean,
      default: false
    },
    // 上传的id
    userCreateInfoId: {
      type: Number,
      default: 0
    },

    /**
     * 上传文件类型
     */
    accept: {
      type: String,
      default: ''
    },

    /**
     * 上传路径
     * @param {String} fileUrl default url '/public/pcimg'
     */
    fileUrl: {
      type: String,
      default: 'public/pcimg'
    }
  },
  data: function data() {
    return {
      // 图片名字
      name: '',
      // 图片数据
      image: {
        name: '',
        url: ''
      },
      // 上传的路径
      // baseUrl: 'http://uuphp.cn:8012/upload/img',
      loading: false,
      uploadDisabled: false
    };
  },
  mounted: function mounted() {
    // 控制图片上线不显示加号
    if (this.limit === this.fileList.length) {
      this.uploadDisabled = true;
    }

    var images = document.getElementsByClassName('el-upload-list__item-thumbnail');

    for (var i = 0; i < images.length; i++) {
      if (this.fileName(images[i].src)) {
        images[i].parentNode.style['padding-left'] = '10px';
        images[i].remove();
      }
    }
  },
  methods: {
    // 返回文件名称
    fileName: function fileName(str) {
      var splits = str.split('/');
      var name = splits[splits.length - 1];
      var suffix = name.split('.');
      suffix = suffix[suffix.length - 1];
      var images = ['bmp', 'jpg', 'jpeg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'dxf', 'ufo', 'eps', 'raw', 'WMF', 'webp', 'avif', 'apng'];
      return images.indexOf(suffix) < 0 ? name : '';
    },
    handleLimit: function handleLimit(file, fileList) {
      if (fileList.length >= this.limit) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }
    },
    // 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      this.$emit('handOperation', true); // 限制类型

      if (this.fileType) {
        var isJPG = file.type === this.fileType;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 pfd 格式!');
        }

        return isJPG;
      } // 限制大小


      var isLt2M = file.size / 1024 / 1024 < this.fileSize;

      if (!isLt2M) {
        this.$message.error("\u4E0A\u4F20\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7".concat(this.fileSize, " MB!"));
      }

      return isLt2M;
    },
    // 文件超出个数限制时的钩子
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 ".concat(this.limit, " \u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ").concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    //  删除 文件
    handleRemove: function handleRemove(file, fileList) {
      var _this = this;

      var img = [];
      fileList.forEach(function (item) {
        if (item.response) {
          img.push(_this.getString(item.response.Data.url));
        } else {
          img.push(_this.getString(item.url));
        }
      });

      if (fileList.length >= this.limit) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }

      var index = [parseInt(this.dataIndex), parseInt(this.dataIndex1), parseInt(this.dataIndex2)];
      this.$emit('handleSuccess', img, index);
    },
    // 上传成功 文件
    handleAvatarSuccess: function handleAvatarSuccess(res, file, fileList) {
      var _this2 = this;

      var img = [];
      fileList.forEach(function (item) {
        if (item.response) {
          img.push(_this2.getString(item.response.Data.url));
        } else {
          img.push(_this2.getString(item.url));
        }
      });
      this.$emit('handOperation', false);
      var index = [parseInt(this.dataIndex), parseInt(this.dataIndex1), parseInt(this.dataIndex2)];
      this.$emit('handleSuccess', img, index);
    },
    // 字符串替换
    getString: function getString(str) {
      return str.replace(new RegExp(this.imgUrl, 'g'), '');
    },
    handleUpload: function handleUpload(file) {
      var date = new Date();
      var hour = date.getHours(); // 时

      var minutes = date.getMinutes(); // 分

      var seconds = date.getSeconds(); //秒

      Oss.onUploadFile(file, 'public/audit/' + this.userCreateInfoId + '/' + '' + (hour * 60 * 60 + minutes * 60 + seconds) + '-' + file.file.name, file.file.name);
    },
    // 处理文件上传
    handleFileUploadOss: function handleFileUploadOss(file) {
      Oss.onUploadFile(file, "".concat(this.fileUrl, "/$id/").concat(new Date().getTime(), "-").concat(file.file.name), file.file.name);
    }
  }
};