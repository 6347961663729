import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import screenfull from '@/components/my-screen';
import { debounce } from '@/utils/debounce';
import { queryTurn, startLottery, stopLottery } from '@/api/lottery';
import editFormatMixin from '@/views/editFormat/editFormatMixin';
export default {
  name: 'StartLotteryHistory',
  components: {
    screenfull: screenfull
  },
  mixins: [editFormatMixin],
  data: function data() {
    return {
      // 定时器
      timeId: null,
      // 摇号 规则
      rand: {},
      // 摇号列表
      lottery: [],
      h: 0,
      // 轮次信息
      list: {},
      // 摇号按钮
      startButton: false,
      // 下一轮摇号
      is_next: false,
      // 传参数据
      query: {},
      // 根据个数改变样式
      heightClass: 'height10',
      // 上一轮按钮
      lastButton: true,
      // 下一路按钮
      nextButton: true,
      styleObject: {},
      classOptionC: {
        step: 0.2,
        // 数值越大速度滚动越快
        limitMoveNum: 3,
        // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false,
        // 是否开启鼠标悬停stop
        direction: 1,
        // 0向下 1向上 2向左 3向右
        openWatch: true,
        // 开启数据实时监控刷新dom
        singleHeight: 0,
        // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0,
        // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000,
        // 单步运动停止的时间(默认值1000ms)
        autoPlay: true // 自动播放

      }
    };
  },
  // 初始化
  created: function created() {
    // 路由传参数据
    var _this$$route$query = this.$route.query,
        id = _this$$route$query.id,
        group_id = _this$$route$query.group_id,
        round = _this$$route$query.round; // 组装数据 给后台

    var data = {
      id: id,
      group_id: group_id,
      round: round
    };
    this.getStartLottery(data);
  },
  watch: {
    // 监听轮次变化，刷新副屏轮次数据
    roundNumber: function roundNumber(newData, oldData) {
      if (this.rand.is_sub_screen === 1) {
        var round_nums = [];

        for (var i = 0; i < this.editFormatList.sub_screenDatas.length; i++) {
          for (var a = 0; a < this.editFormatList.sub_screenDatas[i].rounds.length; a++) {
            if (round_nums.indexOf(newData - this.editFormatList.sub_screenDatas[i].rounds[a]) === -1) {
              round_nums.push(newData - this.editFormatList.sub_screenDatas[i].rounds[a]);
            }
          }
        }

        if (round_nums.length) this.doGetRound(round_nums, newData);
      }
    }
  },
  methods: {
    // 继承& 不继承
    getNumberAdd: function getNumberAdd(index) {
      var indexVal = 0;

      if (this.rand.type_round_code === 1) {
        indexVal = (this.list.this_round - 1) * this.rand.rand_count + index;
      } else {
        indexVal = index;
      }

      if (this.rand.type_group_code === 1) {
        indexVal += this.list.max_code;
      }

      return indexVal;
    },
    // 身份证
    getCard: function getCard(card, show_card) {
      // 隐藏身份证中间
      if (show_card === 1) {
        if (card.length > 15) {
          return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
        } else {
          return card.replace(/^(.{1})(?:\d+)(.{1})$/, '$1******$2');
        }
      } // 只显示最后六个号码
      else {
        return card.substring(card.length - 6);
      }
    },
    nickName: function nickName() {
      var str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var names = str.split(';');
      var newName = '';
      names.forEach(function (name) {
        if (name != null) {
          if (name.length < 3) {
            newName = newName + name[0] + '*';
          }

          if (name.length > 2) {
            newName = newName + name[0] + new Array(name.length - 2).fill('*').join('') + name[name.length - 1];
          }

          if (names.length > 1) newName = newName + ';';
        }
      });
      return newName;
    },
    //  手机正则
    getTel: function getTel(tel) {
      if (tel != null) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, '$1****$2');
      }
    },
    handleUpDown: debounce(function (type) {
      // 路由传参  获取路由参数信息
      var query = JSON.parse(JSON.stringify(this.$route.query)); // 组装数据 给后台

      var data = {};

      if (type === 'up') {
        data = {
          id: query.id,
          group_id: this.list.upper_round === 0 ? this.list.upper_group_id : this.list.sel_group_id,
          round: this.list.upper_round === 0 ? this.list.upper_group_id === 0 ? '' : this.list.upper_group_max_round : this.list.upper_round
        };
      } else {
        data = {
          id: query.id,
          group_id: this.list.lower_round === 0 ? this.list.lower_group_id : this.list.sel_group_id,
          round: this.list.lower_round === 0 ? this.list.lower_group_id === 0 ? '' : 1 : this.list.lower_round
        };
      }

      query.round = data.round; // 改变参数

      query.group_id = data.group_id; // 改变参数

      this.$router.push({
        path: this.$route.path,
        query: query
      }); // 更新路由
      // 刷新页面数据

      this.getStartLottery(data);
    }, 200, true),
    // 获取历史摇号数据
    getStartLottery: function getStartLottery(data) {
      var _this = this;

      queryTurn(data).then(function (res) {
        if (res.status === 200) {
          var _res$data = res.data,
              list = _res$data.list,
              _res$data$list = _res$data.list,
              round_list = _res$data$list.round_list,
              rand = _res$data$list.rand; // 摇号规则

          _this.rand = rand;
          _this.styleObject = {
            background: "url(".concat(_this.imgUrl + rand.logo, ")100%, no-repeat"),
            backgroundSize: 'cover',
            backgroundPosition: 'center, center',
            height: '100vh'
          }; // 摇号基本数据

          _this.list = list;

          if (list.upper_round === 0 && list.upper_group_id === 0) {
            _this.lastButton = false;
          } else {
            _this.lastButton = true;
          }

          if (list.lower_round === 0 && list.lower_group_id === 0) {
            _this.nextButton = false;
          } else {
            _this.nextButton = true;
          }

          _this.lottery = round_list; // 页面个数 高度

          if (rand.rand_count > 10) {
            _this.heightClass = 'height20';
          } else {
            _this.heightClass = 'height10';
          }
        }
      });
    },
    // 点击开始摇号
    handleStartLottery: function handleStartLottery() {
      // 路由传参数据
      var _this$$route$query2 = this.$route.query,
          id = _this$$route$query2.id,
          group_id = _this$$route$query2.group_id,
          round = _this$$route$query2.round; // 组装数据 给后台

      var data = {
        id: id,
        group_id: group_id,
        round: round
      };
      this.$router.push({
        name: 'StartLottery',
        query: data
      });
    },
    // 点击结束摇号
    handleStopLottery: function handleStopLottery() {
      var _this2 = this;

      // 获取摇号结果
      var data = {
        id: this.rand.id,
        group_id: this.list.group_id
      };
      stopLottery(data).then(function (res) {
        if (res.status === 200) {
          var newList = res.data.list.newList;

          if (newList.length < 10) {
            // 计算出来还差几个
            var number = 10 - newList.length;

            for (var i = 0; i < number; i++) {
              newList.push({
                name: '****',
                code: '****',
                tel: '****',
                card: '****',
                other_remark: '****'
              });
            }

            _this2.lottery = newList;
          } else {
            _this2.lottery = newList;
          } //	停止摇号打开下一轮摇号


          _this2.is_next = true; // 停止摇号过后 就不能显示取消按钮

          _this2.startButton = false;
        }
      });
    }
  }
};