/**
 * @author yang
 * @time 2021/4/19
 * @return {api} 大屏显示接口
 * */
import request from '@/utils/request'; // ------------屏幕管理------------------
// 活动选择列表

export function activeSelect(params) {
  return request({
    url: '/led/Tab',
    method: 'get',
    params: params
  });
} // 屏幕列表

export function screenLists(params) {
  return request({
    url: '/led/List',
    method: 'get',
    params: params
  });
} // 添加屏幕

export function addScreen(params) {
  return request({
    url: '/led/Add',
    method: 'get',
    params: params
  });
} // 删除屏幕

export function delScreen(params) {
  return request({
    url: '/led/Del',
    method: 'get',
    params: params
  });
} // 获取屏幕设置

export function getScreenSetting(params) {
  return request({
    url: '/led/Set',
    method: 'get',
    params: params
  });
} // 设置屏幕参数

export function setScreenSetting(data) {
  return request({
    url: '/led/Set',
    method: 'post',
    data: data
  });
} // 控制人数 获取人数配置

export function getPersonNum(params) {
  return request({
    url: '/led/Number',
    method: 'get',
    params: params
  });
} // 控制人数 修改人数配置

export function setPersonNum(data) {
  return request({
    url: '/led/Number',
    method: 'post',
    data: data
  });
} // -----------通用设置---------------
// 获取通用设置

export function getBaseSetting(params) {
  return request({
    url: '/led/Conf',
    method: 'get',
    params: params
  });
} // 设置通用设置

export function setBaseSetting(data) {
  return request({
    url: '/led/Conf',
    method: 'post',
    data: data
  });
} // -----------大屏显示接口-------------
// 屏幕演示

export function getLedShow(params) {
  return request({
    url: '/led/Show',
    method: 'get',
    params: params
  });
} // 屏幕拖拽位置接口

export function setPos(data) {
  return request({
    url: '/led/setCoordinate',
    method: 'post',
    data: data
  });
} // 屏幕保存位置接口

export function setColor(data) {
  return request({
    url: '/led/SetColor',
    method: 'post',
    data: data
  });
} // 大屏显示活动时间

export function getActiveTime(params) {
  return request({
    url: '/Index/Time',
    method: 'get',
    params: params
  });
} // 大屏显示 选房记录

export function getChoiseHouseLog(params) {
  return request({
    url: '/led/ShowOrder',
    method: 'get',
    params: params
  });
} // 大屏显示 右侧订单数据

export function getShowOrder(params) {
  return request({
    url: '/led/ShowOrder',
    method: 'get',
    params: params
  });
} // 大屏显示 左侧房间数据

export function getShowRoom(params) {
  return request({
    url: '/led/ShowRoom',
    method: 'get',
    params: params
  });
} // 大屏显示 右侧房源数据

export function getShowCate(params) {
  return request({
    url: '/led/ShowCate',
    method: 'get',
    params: params
  });
}
export function ShowUser(params) {
  return request({
    url: '/led/ShowUser',
    method: 'get',
    params: params
  });
} //    大屏活动后设置接口

export function SetConfColor(data) {
  return request({
    url: '/led/ConfColor',
    method: 'post',
    data: data
  });
}
export function GetConfColor(params) {
  return request({
    url: '/led/GetConfColor',
    method: 'get',
    params: params
  });
}
export function DelConfColor(data) {
  return request({
    url: '/led/DelConfColor',
    method: 'post',
    data: data
  });
}
export function DiySet(data) {
  //右侧 屏幕DIY设置
  return request({
    url: '/led/DiySet',
    method: 'post',
    data: data
  });
}
export function DiyData(data) {
  //右侧 屏幕DIY设置 屏幕列表
  return request({
    url: '/led/DiyData',
    method: 'get',
    data: data
  });
}
export function DiyCopy(data) {
  //右侧 屏幕DIY设置 复制屏幕
  return request({
    url: 'led/DiyCopy',
    method: 'post',
    data: data
  });
}
export function ShowReserve(params) {
  // 获取预留列表
  return request({
    url: '/led/ShowReserve',
    method: 'get',
    params: params
  });
}