import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.pad-end.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import auditState from './components/auditState';
import userAudit from './components/userAudit';
import numList from './components/numList';
import { numInfo, bindNum, unbindNum, userList, numberList, printInfo, sendNoSelect, sendNoAutoSelect, sendNoDelBind } from '@/api/sendNumber';
import { adminParameter } from '@/api/public';
import checkPermission from '@/utils/permission';
import { encode } from "js-base64";
export default {
  components: {
    numList: numList,
    userAudit: userAudit,
    auditState: auditState
  },
  data: function data() {
    return {
      // 弹窗配置：1多客户  2未找到客户  3占号弹窗
      // 4占号成功  5占号失败 6派号确认 7派号失败
      // 8未达到派号资格  9退号确认
      dialog: {
        show: false,
        type: 0
      },
      // 右侧抽屉配置
      drawer: {
        show: false,
        direction: 'rtl'
      },
      // 身份证识别器搜索参数
      searchParams: {
        card: ''
      },
      // 输入框搜索内容
      searchText: '',
      // 占号备注
      remark: '',
      // 号码类型列表
      numTypeList: [],
      // 号码类型下标
      numTypeIndex: 0,
      // 选中的号码类型
      nowNumType: {},
      // 当前选中的号码
      nowNum: '',
      // 当前选中号码的外显名称
      nowNumName: '',
      // 搜索出的用户列表
      userList: [],
      // 选中的客户下标
      selectUserIndex: 0,
      // 当前派号的客户
      nowUser: {},
      // 号码详情
      numDetail: {},
      // 更新子组件
      random: 0,
      // 是否有打印权限
      hasPrint: checkPermission(['admin', 'sendno/print']),
      // 系统配置
      audit: {},
      projectConfig: {},
      // 配置
      // 一组个数
      oneGroupNum: 10,
      numType: 0,
      delShowDialog: false,
      min: 0
    };
  },
  computed: {
    nowSelectNum: function nowSelectNum() {
      var _this$nowNumType = this.nowNumType,
          prefix = _this$nowNumType.prefix,
          digit = _this$nowNumType.digit;
      var nowNumName = this.nowNumName;

      if (nowNumName) {
        return this.createNum(prefix, nowNumName, digit);
      } else {
        return '请选择号码';
      }
    }
  },
  created: function created() {
    var _this = this;

    // 获取系统配置
    adminParameter().then(function (res) {
      _this.audit = res.data.audit || {};
      _this.projectConfig = res.data;

      _this.reqNumList().then(function (res) {
        _this.$refs.numList.scorllNum(_this.max_num); // this.nowNum = this.max_num
        // this.numType = 3
        // this.random = Math.ceil(Math.random(10, 100) * 100)

      });
    });
  },
  mounted: function mounted() {},
  methods: {
    delHideDrawer: function delHideDrawer() {
      this.delShowDialog = false;
    },
    //  确认作废
    returnSussess: function returnSussess() {
      var _this2 = this;

      var params = {
        id: this.numDetail.id
      };
      sendNoDelBind(params).then(function (res) {
        var _ref = res.data || {},
            msg = _ref.msg;

        _this2.$message({
          type: 'success',
          message: msg
        });

        _this2.hideDialog();

        _this2.hideDrawer();

        _this2.refresh();
      });
    },
    endRowNum: function endRowNum() {
      var _this3 = this;

      var tag_id = 0;

      if (this.numTypeList.length > 0) {
        tag_id = this.numTypeList[this.numTypeIndex].id;
      }

      var params = {
        tag_id: tag_id
      };
      sendNoAutoSelect(params).then(function (res) {
        _this3.nowNum = res.data.num;
        _this3.numType = 1;
        _this3.random = Math.ceil(Math.random(10, 100) * 100);
      });
    },
    actualRowNum: function actualRowNum() {
      var _this4 = this;

      var tag_id = 0;

      if (this.numTypeList.length > 0) {
        tag_id = this.numTypeList[this.numTypeIndex].id;
      }

      var params = {
        tag_id: tag_id
      };
      sendNoSelect(params).then(function (res) {
        _this4.nowNum = res.data.num;
        _this4.numType = 1;
        _this4.random = Math.ceil(Math.random(10, 100) * 100);
      });
    },
    refresh: function refresh() {
      var _this5 = this;

      return new Promise(function (resolve, reject) {
        _this5.reqNumList().then(function (res) {
          resolve();
        });

        _this5.clearNowUser();
      });
    },
    // 拼接号码格式
    createNum: function createNum(prefix, numName, digit) {
      var paihao_rule = this.audit.paihao_rule; // 分段序号

      if (paihao_rule == 1) {
        return "".concat(prefix || '').concat(numName);
      } // 普通序号
      else {
        var fill = Array(digit).fill(0).join('');
        return prefix + "".concat(fill).concat(numName).slice(-digit);
      } // return `${prefix || ''}${numName}`

    },
    // 自动搜索
    getUserList: function getUserList() {
      this.reqUserList(this.searchParams);
    },
    // 手动搜索
    search: function search() {
      if (this.searchText === '') {
        this.$message({
          type: 'warning',
          message: '请输入搜索内容！'
        });
        return;
      }

      this.reqUserList({
        tel: this.searchText.trim()
      });
    },
    // 获取客户列表
    reqUserList: function reqUserList(params) {
      var _this6 = this;

      this.selectUserIndex = 0;
      userList(params).then(function (res) {
        var _ref2 = res.data.data || {},
            users = _ref2.users;

        _this6.userList = users;

        if (!users || users.length == 0) {
          _this6.showDialog(2);
        } else if (users.length == 1) {
          _this6.userQualifications();
        } else if (users.length > 1) {
          _this6.showDialog(1);
        } // 清空旧的搜索条件


        _this6.searchParams.card = '';
        _this6.searchText = '';
      });
    },
    // 获取号码列表
    reqNumList: function reqNumList(isRefresh) {
      var _this7 = this;

      return new Promise(function (resove, reject) {
        numberList().then(function (res) {
          if (isRefresh === true) {
            _this7.$message({
              type: 'success',
              message: '刷新成功！'
            });
          }

          var list = res.data.list || [];
          var max = _this7.audit.paihao_max || 5000;
          var min = _this7.audit.paihao_min || 1;
          list.forEach(function (item) {
            var _item$list;

            // 之前的写法是有一个位数，max为5000，现在没有位数，max为动态，所以不需要这样的判断
            // 分段序号直接5000个
            if (_this7.audit.paihao_rule == 1) {
              item.total_count = max;
            } // 普通序号
            else {
              // 手动采用配置中的位数
              item.digit = _this7.audit.paihao_no; // 手动计算号码总数额

              if (item.digit >= (max + '').length) {
                item.total_count = max;
              } else {
                item.total_count = parseInt(Array(item.digit).fill(9).join(''));
              }
            } // item.total_count = max
            // 手动拼接已占派号的结构状态


            var obj = {};
            (_item$list = item.list) === null || _item$list === void 0 ? void 0 : _item$list.forEach(function (no) {
              obj[no.num] = no.user_id;
            });
            item.listObj = obj; // 设置最大号外显名称

            item.show_max_num = _this7.setNumName(parseInt(item.max_num || 0));
            _this7.max_num = item.max_num;
          });

          _this7.clearNowNum();

          _this7.min = min;
          _this7.numTypeList = list;
          _this7.nowNumType = list[_this7.numTypeIndex];
          resove();
        });
      });
    },
    // 拼接号码外显名称
    setNumName: function setNumName(num) {
      var _this$audit = this.audit,
          paihao_rule = _this$audit.paihao_rule,
          no_1 = _this$audit.paihao_num1,
          no_2 = _this$audit.paihao_num2; // 分段序号

      if (paihao_rule == 1) {
        num -= 1;
        var num1 = parseInt(num / this.oneGroupNum) + 1;
        var num2 = parseInt(num % this.oneGroupNum) + 1;
        var max1 = parseInt('1'.padEnd(no_1, '0'));
        var max2 = parseInt('1'.padEnd(no_2, '0'));
        return "".concat(num1 < max1 ? num1.toString().padStart(no_1, '0') : num1, "-").concat(num2 < max2 ? num2.toString().padStart(no_2, '0') : num2);
      } // 普通序号
      else {
        return num;
      }
    },
    // 设置当前用户信息
    setNowUser: function setNowUser(user) {
      // 检查当前用户是否已经派号
      if (user.num || user.tag_id) {
        this.$message({
          type: 'success',
          message: '客户已派号'
        });
        this.clearNowNum();
        this.reqNumDetail(user.num, user.tag_id);
        return;
      }

      this.nowUser = user;
      this.hideDialog(); // 当前没有已选中号码，才自动选中

      if (!this.nowNum) this.autoSelectNum(); // 查找到用户之后关闭socket
      // 切换客户后再开启扫身份证功能

      this.send('close');
    },
    // 判断所选客户是否达到派号资格
    userQualifications: function userQualifications() {
      var user = this.userList[this.selectUserIndex];

      var _ref3 = this.audit || {},
          register = _ref3.send_num_rule_register,
          identify = _ref3.send_num_rule_identify; // 都不限制


      if (register == 0 && identify == 0) {
        this.setNowUser(user);
      } else {
        var re_state = true;
        var id_state = true; // 报名未提交

        if (register == 1) {
          if (user.register_status < 0 || user.register_status == 999) {
            re_state = false;
          }
        } // 报名未通过
        else if (register == 2 && user.register_status != 3) {
          re_state = false;
        } // 认筹未提交


        if (identify == 1) {
          if (user.identify_status < 0 || user.identify_status == 999) {
            id_state = false;
          }
        } // 认筹未通过
        else if (identify == 2 && user.identify_status != 3) {
          id_state = false;
        }

        if (this.projectConfig.list.is_identify === 1) {
          // 报名认筹都满足条件，才设置用户
          if (re_state && id_state) {
            this.setNowUser(user);
          } else {
            this.showDialog(8);
          }
        } else {
          if (re_state) {
            this.setNowUser(user);
          } else {
            this.showDialog(8);
          }
        }
      }
    },
    // 清除当前选中的号码
    clearNowNum: function clearNowNum() {
      this.nowNum = '';
      this.nowNumName = '';
    },
    // 清空当前用户信息
    clearNowUser: function clearNowUser() {
      this.nowUser = {};
      this.userList = [];
      this.selectUserIndex = 0;
      this.send('open');
    },
    // 选中搜索出的用户
    checkUser: function checkUser(index) {
      this.selectUserIndex = index;
    },
    // 切换顶部号码类型
    changeNumType: function changeNumType(index) {
      if (this.numTypeIndex == index) return;
      this.numTypeIndex = index;
      this.reqNumList();
    },
    // 选中号码
    selectNum: function selectNum(item) {
      this.nowNum = item.num;
      this.nowNumName = item.name;
    },
    // 占空位
    spaceNum: function spaceNum() {
      var _this8 = this;

      if (!this.nowNum) return;
      var params = {
        tag_id: this.nowNumType.id,
        num: this.nowNum,
        user_id: 0,
        node: this.remark
      };
      bindNum(params).then(function (res) {
        var _ref4 = res.data || {},
            msg = _ref4.msg; // 有错误消息，就弹出失败弹窗


        if (msg) {
          _this8.showDialog(5, msg);
        } else {
          _this8.showDialog(4);
        }
      });
    },
    // 派号确认
    bindNumToUser: function bindNumToUser() {
      var _this9 = this;

      if (!this.nowNum) {
        this.$message.warning('请选择认筹号');
        return;
      }

      var params = {
        tag_id: this.nowNumType.id,
        num: this.nowNum,
        user_id: this.nowUser.id
      };
      bindNum(params).then(function (res) {
        var _ref5 = res.data || {},
            msg = _ref5.msg,
            log = _ref5.log,
            info = _ref5.info; // 有错误消息，就弹出失败弹窗


        if (msg) {
          if (msg.includes('占用')) {
            _this9.showDialog(7, msg, 1);
          } else if (msg.includes('已派号')) {
            _this9.showDialog(7, msg, 2, log);
          } else if (msg.includes('客户未达到派号资格')) {
            _this9.showDialog(8);
          } else if (msg.includes('认筹号超过规定最大值')) {
            _this9.hideDialog();

            _this9.$message.error('认筹号超过规定最大值');
          }
        } else {
          var _this9$dialog$data;

          // 派号成功，直接弹出详情
          _this9.reqNumDetail(params.num, params.tag_id);

          if ((_this9$dialog$data = _this9.dialog.data) !== null && _this9$dialog$data !== void 0 && _this9$dialog$data.print) {
            // 将派号打印ID存入弹窗中
            _this9.dialog.data.pritID = info.id;

            _this9.printReceipt(true);
          }

          _this9.$message({
            type: 'success',
            message: '派号成功'
          });

          _this9.refresh();

          _this9.hideDialog();
        }
      });
    },
    // 确认派号
    confirmSpace: function confirmSpace(isPrint) {
      if (!this.nowNum) {
        this.$message.warning('请选择认筹号');
        return;
      }

      var data = {};
      if (isPrint === true) data.print = true;
      this.showDialog(6, null, null, data);
    },
    // 退号确认
    returnConfirm: function returnConfirm() {
      this.showDialog(9);
    },
    // 确认退号
    returnNum: function returnNum() {
      var _this10 = this;

      unbindNum({
        id: this.numDetail.id
      }).then(function () {
        _this10.$message({
          type: 'success',
          message: '操作成功！'
        });

        _this10.hideDialog();

        _this10.hideDrawer();

        _this10.delHideDrawer();

        _this10.refresh();
      });
    },
    // 弹窗中的按钮操作
    dialogOption: function dialogOption(showDetail) {
      if (showDetail === true) {
        this.reqNumDetail(this.nowNum, this.nowNumType.id);
      }

      this.hideDialog();
      this.refresh();
    },
    // 换号重派
    againBindNumToUser: function againBindNumToUser() {
      this.autoSelectNum();
      this.hideDialog();
    },
    // 自动选号
    autoSelectNum: function autoSelectNum() {
      var _this11 = this;

      this.reqNumList().then(function () {
        _this11.random = Math.ceil(Math.random(10, 100) * 100);
        _this11.numType = 2;
      });
    },
    // 打印认筹单
    printReceipt: function printReceipt(print) {
      var _this12 = this;

      var isWps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      // 没有认筹单模板，就弹出提示
      if (!this.audit.identify_word) {
        this.$message({
          type: 'error',
          message: '未配置认筹单模板，打印失败！'
        });
        return;
      }

      var params = {
        id: this.numDetail.id
      };

      if (print === true) {
        params.id = this.dialog.data.pritID;
      }

      printInfo(params).then(function (res) {
        if (_this12.projectConfig.list.print_type == 1) {
          //wps打印
          var fileId = res.data.wps.id;

          var path = _this12.$router.resolve({
            path: '/wpsPrint',
            query: {
              fileId: fileId
            }
          });

          window.open("".concat(path.href), '_blank');
        } else if (_this12.projectConfig.list.print_type == 0) {
          //原有office打印
          var url = "https://view.officeapps.live.com/op/view.aspx?src=".concat(_this12.host, "/").concat(res.data.path);
          window.open(url);
        } else if (_this12.projectConfig.list.print_type == 2) {
          // kkfileview打印
          var urldata = encode("".concat(_this12.host, "/").concat(res.data.path));

          var _url = "http://word.yun-xk.com/onlinePreview?url=".concat(urldata, "&officePreviewType=pdf");

          window.open(_url);
        }
      });
    },
    // 号码详情
    reqNumDetail: function reqNumDetail(num, tag_id) {
      var _this13 = this;

      var params = {
        tag_id: typeof tag_id == 'number' ? tag_id : this.nowNumType.id,
        num: typeof num == 'number' ? num : this.nowNum
      };
      numInfo(params).then(function (res) {
        var info = res.data.info || {};

        if (info.num_code) {
          _this13.numDetail = info || {};

          if (res.data.info.user_id === -1) {
            _this13.delShowDialog = true;
          } else {
            _this13.showDrawer();
          }
        } else {
          _this13.refresh().then(function () {
            _this13.$refs.numList.onlySelectNum(params.num);
          });
        }
      });
    },
    // 通过用户查看派号详情
    userNumDetail: function userNumDetail() {
      var _ref6 = this.dialog.data || {},
          num = _ref6.num,
          tag_id = _ref6.tag_id;

      this.reqNumDetail(num, tag_id);
    },
    // 跳转到派号列表页面
    toSendNumList: function toSendNumList() {
      this.$router.push({
        name: 'SendNumberList'
      });
    },
    showSpaceDialog: function showSpaceDialog() {
      if (!this.nowNum) {
        this.$message.warning('请选择认筹号');
        return;
      }

      this.showDialog(3);
    },
    showDialog: function showDialog(type, tips, code, data) {
      this.dialog = {
        show: true,
        type: type,
        tips: tips,
        code: code,
        data: data
      };
    },
    hideDialog: function hideDialog() {
      this.remark = '';
      this.dialog.show = false;
    },
    showDrawer: function showDrawer() {
      this.hideDialog();
      this.drawer.show = true;
    },
    hideDrawer: function hideDrawer() {
      this.drawer.show = false;
    }
  }
};