var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "top-fixed" }, [
        _c(
          "div",
          { staticClass: "title-top-box" },
          [
            _c("div", { staticClass: "title account-status-wrapper" }, [
              _c("span", [_vm._v("新增客户信息")]),
              _c(
                "div",
                { staticClass: "account-status mL20" },
                [
                  _vm.form.user.status === 1
                    ? _c("span", { staticClass: "status1" }, [
                        _vm._v("账号状态：启用"),
                      ])
                    : _c("span", { staticClass: "status2" }, [
                        _vm._v("账号状态：禁用"),
                      ]),
                  _c("el-switch", {
                    attrs: {
                      type: "number",
                      size: "small",
                      "active-value": 1,
                      "inactive-value": 0,
                    },
                    model: {
                      value: _vm.form.user.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.user, "status", $$v)
                      },
                      expression: "form.user.status",
                    },
                  }),
                  _vm.show_is_buy_rand
                    ? [
                        _vm.form.user.is_rand === 1
                          ? _c("span", { staticClass: "status1" }, [
                              _vm._v("测试摇号结果：启用"),
                            ])
                          : _c("span", { staticClass: "status1" }, [
                              _vm._v("测试摇号结果：禁用"),
                            ]),
                        _c("el-switch", {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { "active-value": 1, "inactive-value": 0 },
                          model: {
                            value: _vm.form.user.is_rand,
                            callback: function ($$v) {
                              _vm.$set(_vm.form.user, "is_rand", $$v)
                            },
                            expression: "form.user.is_rand",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c(
              "el-button",
              {
                staticClass: "greens-btn",
                attrs: { size: "small" },
                on: { click: _vm.handleSave },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "detail-box mT10" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c("div", { staticClass: "block" }, [
                _c(
                  "div",
                  { staticClass: "model" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: _vm.isPC ? 16 : 24 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "name",
                                    attrs: {
                                      label: "姓名：",
                                      prop: "user.name",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "50",
                                        "show-word-limit": "",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.form.user.name,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "name",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.user.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "tel",
                                    attrs: {
                                      label: "登录手机号：",
                                      prop: "user.tel",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "11",
                                        "show-word-limit": "",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.form.user.tel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "tel",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.user.tel",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticClass: "card",
                                    attrs: {
                                      label: "证件号码：",
                                      prop: "user.card",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        "show-word-limit": "",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.form.user.card,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "card",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.user.card",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label:
                                        _vm.projectList.user_code_name + "：",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        maxlength: "20",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.form.user.code,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "code",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.user.code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "置业顾问：" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          size: "small",
                                          placeholder: "姓名搜索或选择",
                                        },
                                        model: {
                                          value: _vm.admin_id,
                                          callback: function ($$v) {
                                            _vm.admin_id = _vm._n($$v)
                                          },
                                          expression: "admin_id",
                                        },
                                      },
                                      _vm._l(_vm.userList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "限购数量：",
                                      prop: "user.buy_count",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        min: "0",
                                        max: "30",
                                        size: "small",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.form.user.buy_count,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "buy_count",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.user.buy_count",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.matching_order === 1 ||
                            _vm.is_must_buy_room >= 1
                              ? _c(
                                  "el-col",
                                  {
                                    style: {
                                      opacity:
                                        _vm.is_must_buy_room >= 1 ? 0 : 1,
                                    },
                                    attrs: { span: _vm.isPC ? 12 : 24 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "车位最小认购数量：",
                                          prop: "user.buy_min_car",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            min: "0",
                                            size: "small",
                                            type: "number",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.onInputUserBlur(
                                                "buy_min_car"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.user.buy_min_car,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.user,
                                                "buy_min_car",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "form.user.buy_min_car",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.matching_order === 1 ||
                            _vm.is_must_buy_room >= 1
                              ? _c(
                                  "el-col",
                                  { attrs: { span: _vm.isPC ? 12 : 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "车位最大认购数量：",
                                          prop: "user.buy_max_car",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            min: "0",
                                            size: "small",
                                            type: "number",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.onInputUserBlur(
                                                "buy_max_car"
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.form.user.buy_max_car,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.user,
                                                "buy_max_car",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "form.user.buy_max_car",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "分批选房序号：" } },
                                  [
                                    _c("el-input", {
                                      attrs: { size: "small", type: "number" },
                                      model: {
                                        value: _vm.form.user.num,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "num",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.user.num",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "选房时长限定：" } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c("inputText", {
                                          attrs: {
                                            "text-value": _vm.form.user.min,
                                            "input-w": 100,
                                            "input-text": "分",
                                          },
                                          on: {
                                            handleInputValue: _vm.handleMin,
                                          },
                                        }),
                                        _vm.isInput
                                          ? _c("inputText", {
                                              attrs: {
                                                "text-value": _vm.form.user.sec,
                                                "input-w": 100,
                                                "input-text": "秒",
                                              },
                                              on: {
                                                handleInputValue: _vm.handleSec,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "诚意金额(元)：" } },
                                  [
                                    _vm.projectList.cate_room
                                      ? _c(
                                          "div",
                                          { staticClass: "flex jumpRoom" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex align-items blocks mR15",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "flex-shrink",
                                                  },
                                                  [_vm._v("住宅")]
                                                ),
                                                _c("el-input", {
                                                  attrs: {
                                                    onkeyup:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    onafterpaste:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    size: "small",
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.price.room_price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.price,
                                                        "room_price",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "form.price.room_price",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex align-items blocks width2",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "flex-shrink",
                                                  },
                                                  [_vm._v("份数")]
                                                ),
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.form.price
                                                        .room_price > 0
                                                        ? false
                                                        : true,
                                                    onkeyup:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    onafterpaste:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    size: "small",
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.price
                                                        .room_number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.price,
                                                        "room_number",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "form.price.room_number",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.projectList.cate_car
                                      ? _c(
                                          "div",
                                          { staticClass: "flex jumpCar" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex align-items blocks mR15",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "flex-shrink",
                                                  },
                                                  [_vm._v("车位")]
                                                ),
                                                _c("el-input", {
                                                  attrs: {
                                                    onkeyup:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    onafterpaste:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    size: "small",
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.price.car_price,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.price,
                                                        "car_price",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "form.price.car_price",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex align-items blocks width2",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "flex-shrink",
                                                  },
                                                  [_vm._v("份数")]
                                                ),
                                                _c("el-input", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.form.price.car_price >
                                                      0
                                                        ? false
                                                        : true,
                                                    onkeyup:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    onafterpaste:
                                                      "if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                                                    size: "small",
                                                    type: "number",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.price.car_number,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form.price,
                                                        "car_number",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "form.price.car_number",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "客户分类：" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          filterable: "",
                                          "allow-create": "",
                                          "default-first-option": "",
                                          placeholder: "请选择客户分类",
                                        },
                                        model: {
                                          value: _vm.tagvalue,
                                          callback: function ($$v) {
                                            _vm.tagvalue = $$v
                                          },
                                          expression: "tagvalue",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.name,
                                          attrs: {
                                            label: item.name,
                                            value: item.name,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#666666",
                                          "font-size": "10px",
                                          "font-weight": "100",
                                          "line-height": "14px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "分类名称越短越好且不能包含关系；"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "摇号分组：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        maxlength: "80",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.form.user.rand_group,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "rand_group",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.user.rand_group",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: _vm.isPC ? 12 : 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "特殊排序：" } },
                                  [
                                    _c("el-input-number", {
                                      attrs: {
                                        min: 0,
                                        size: "small",
                                        type: "number",
                                      },
                                      model: {
                                        value: _vm.form.user.rand_sort,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "rand_sort",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "form.user.rand_sort",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 24 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "其他信息：" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "small",
                                        type: "textarea",
                                        maxlength: "255",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.form.user.other_remark,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.user,
                                            "other_remark",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "form.user.other_remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.is_sign
                ? _c(
                    "div",
                    { staticClass: "block mT10 checkTrend" },
                    [
                      _c("div", { staticClass: "title-h2" }, [
                        _c(
                          "div",
                          { staticClass: "flex align-items" },
                          [
                            _c("div", { staticClass: "jump" }, [
                              _vm._v("产权人信息"),
                            ]),
                            _vm.is_addButton
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "greens-btn mL10",
                                    attrs: {
                                      icon: "el-icon-plus",
                                      size: "mini",
                                    },
                                    on: { click: _vm.handleAddList },
                                  },
                                  [_vm._v("增加产权人")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "trendRules",
                          attrs: { rules: _vm.trendRules, model: _vm.form },
                        },
                        [
                          _c(
                            "el-row",
                            { staticClass: "mT15", attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: _vm.isPC ? 16 : 24 } },
                                _vm._l(
                                  _vm.form.user_audit_owner,
                                  function (item, i) {
                                    return _c(
                                      "el-col",
                                      {
                                        key: i,
                                        attrs: { span: _vm.isPC ? 12 : 24 },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "title-button-box flex align-items",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "buttons",
                                                attrs: { size: "mini" },
                                              },
                                              [
                                                _vm._v(
                                                  "第" +
                                                    _vm._s(_vm.changeData(i)) +
                                                    "产权人"
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mL10 delete",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleDelList(i)
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mL10 delete",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleSynchro(i)
                                                  },
                                                },
                                              },
                                              [_vm._v("同步")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "renName",
                                            attrs: {
                                              label: "姓名：",
                                              prop:
                                                "user_audit_owner." +
                                                i +
                                                ".real_name",
                                              rules: _vm.trendRules.real_name,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "50",
                                                "show-word-limit": "",
                                                size: "small",
                                              },
                                              model: {
                                                value: item.real_name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "real_name",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.real_name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "renTel",
                                            attrs: {
                                              label: "手机号：",
                                              prop:
                                                "user_audit_owner." +
                                                i +
                                                ".tel",
                                              rules: _vm.trendRules.tel,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "11",
                                                "show-word-limit": "",
                                                size: "small",
                                              },
                                              model: {
                                                value: item.tel,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "tel",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.tel",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件类型：" } },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  filterable: "",
                                                  clearable: "",
                                                  size: "small",
                                                  placeholder: "请选择证件类型",
                                                },
                                                model: {
                                                  value: item.card_type,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "card_type",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.card_type",
                                                },
                                              },
                                              _vm._l(
                                                _vm.card_types,
                                                function (ite) {
                                                  return _c("el-option", {
                                                    key: ite.card_type,
                                                    attrs: {
                                                      label: ite.card_type,
                                                      value: ite.card_type,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "renCard",
                                            attrs: {
                                              label: "证件号码：",
                                              prop:
                                                "user_audit_owner." +
                                                i +
                                                ".card",
                                              rules:
                                                item.card_type === "身份证"
                                                  ? _vm.trendRules.card
                                                  : null,
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "18",
                                                "show-word-limit": "",
                                                size: "small",
                                                type: "text",
                                              },
                                              model: {
                                                value: item.card,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "card",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "item.card",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "通讯地址：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "80",
                                                "show-word-limit": "",
                                                size: "small",
                                              },
                                              model: {
                                                value: item.tx_address,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "tx_address",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.tx_address",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "其他系统FID：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "80",
                                                "show-word-limit": "",
                                                size: "small",
                                              },
                                              model: {
                                                value: item.address,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "address", $$v)
                                                },
                                                expression: "item.address",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _c("el-col", { attrs: { span: 8 } }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.query.round
                ? _c("div", { staticClass: "block mT15" }, [
                    _c("div", { staticClass: "title-h2" }, [
                      _vm._v(" 关联场次 "),
                      _c("span", [
                        _vm._v("请在【开盘客户】页面，点击关联场次"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _c("id-card-drive", {
        ref: "cardDrive",
        attrs: { isTips: false },
        on: { getUserInfo: _vm.getUserInfo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }