import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Print } from '@/api/lottery';
import utils from '@/utils/utils';
var cha = new Date().getFullYear() - 1949 - 16;
export default {
  name: 'index',
  props: {
    template_ID: {
      type: Number
    }
  },
  data: function data() {
    return {
      dataList: [],
      rand: {},
      selectIds: [],
      rand_id: 0,
      project_name: '项目名称',
      template_id: 0
    };
  },
  created: function created() {
    var template_ID = this.$route.query.template_ID;

    if (this.$route.query.ids && this.$route.query.rand_id) {
      this.template_id = this.$route.query.template_id ? this.$route.query.template_id : 0;
      var selectIds = this.$route.query.ids ? this.$route.query.ids.split(',') : [];
      this.rand_id = +this.$route.query.rand_id;

      for (var i = 0; i < selectIds.length; i++) {
        this.selectIds.push(+selectIds[i]);
      }

      this.doGetPrint(this.selectIds);
    } else if (this.template_ID === 0 || this.$route.query.template_ID == 0) {
      var log_list = [];

      for (var _i = 1; _i < 11; _i++) {
        log_list.push({
          code: _i,
          name: '名称' + _i,
          user_code: this.$adminList.user_code_name + _i,
          card: this.str()
        });
      }

      this.dataList = [{
        num: 1,
        created_at: new Date().getTime() / 1000,
        print_time: new Date().getTime() / 1000,
        log_list: log_list
      }];
      this.project_name = '项目名称';
      this.template_id = 0;
    } else if (this.template_ID === 1 || this.$route.query.template_ID == 1) {
      var _log_list = [];

      for (var _i2 = 1; _i2 < 11; _i2++) {
        _log_list.push({
          code: _i2,
          name: '名称' + _i2,
          user_code: this.$adminList.user_code_name + _i2
        });
      }

      this.dataList = [{
        num: 1,
        created_at: new Date().getTime() / 1000,
        print_time: new Date().getTime() / 1000,
        log_list: _log_list
      }];
      this.project_name = '项目名称';
      this.template_id = 1;
    } else if ([2, 3, 4, 5].includes(Number(template_ID))) {
      this.handler();
      this.template_id = template_ID;
    } else if (this.template_ID === 7 || this.$route.query.template_ID == 7) {
      var _log_list2 = [];

      for (var _i3 = 1; _i3 < 11; _i3++) {
        _log_list2.push({
          code: 1,
          name: '名称' + _i3,
          user_code: this.$adminList.user_code_name + _i3
        });
      }

      this.dataList = [{
        num: 1,
        created_at: new Date().getTime() / 1000,
        print_time: new Date().getTime() / 1000,
        log_list: _log_list2
      }];
      this.project_name = '项目名称';
      this.template_id = 7;
    }
  },
  mounted: function mounted() {},
  methods: {
    doGetPrint: function doGetPrint() {
      var _this = this;

      if (this.printTimeOut) return;
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      Print({
        rand_id: this.rand_id,
        ids: this.selectIds
      }).then(function (res) {
        _this.rand = res.data.info.rand;
        _this.project_name = res.data.info.name;
        _this.dataList = res.data.info.list || [];

        _this.$nextTick(function () {
          _this.printTimeOut = setTimeout(function () {
            window.print();
            clearTimeout(_this.printTimeOut);
          }, 500);
        });

        _this.loading.close();
      }).catch(function (res) {
        _this.loading.close();
      });
    },
    // 改变时间格式
    doGetTime: function doGetTime(time) {
      return utils.formatDate(new Date(time), 'yyyy年MM月dd日 hh:mm:ss');
    },
    handler: function handler() {
      var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
      var log_list = [];

      for (var i = 1; i <= count; i++) {
        log_list.push({
          code: i,
          card: this.str(),
          name: '名称' + i,
          user_code: this.$adminList.user_code_name + i
        });
      }

      this.dataList = [{
        num: 1,
        created_at: new Date().getTime() / 1000,
        print_time: new Date().getTime() / 1000,
        log_list: log_list
      }];
    },

    /**
     * @description
     */
    str: function str() {
      var prefixes = ['110108', '120100', '130200', '140300', '150400', '210500', '220600', '230700', '310100', '320200', '330300', '340400', '350500', '360600', '370700', '410100', '420200', '430300', '440400', '450500', '500100', '510100', '520200', '610100', '620100', '630100', '640100', '650100'];
      var lastTwo = Math.floor(Math.random() * 99); // 随机生成两位数作为尾号

      var year = 1949 + Math.floor(Math.random() * cha); // 随机生成年份

      var month = '0' + (1 + Math.floor(Math.random() * 12)); // 月份，前面补零

      var day = '0' + (1 + Math.floor(Math.random() * 30)); // 日期，前面补零

      var randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)]; // 随机前6位地区代码

      var sequence = year.toString() + month.slice(-2) + day.slice(-2); // 组合出生日期

      var checkCode = this.getCheckCode(randomPrefix + sequence + lastTwo); // 生成校验码

      return randomPrefix + sequence + lastTwo + checkCode;
    },
    getCheckCode: function getCheckCode(idNumber) {
      var weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权系数

      var checkCodeTable = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码对应表

      var sum = 0;

      for (var i = 0; i < idNumber.length; i++) {
        sum += parseInt(idNumber[i], 10) * weights[i];
      }

      return checkCodeTable[sum % 11];
    },
    // 身份证
    getCard: function getCard(card) {
      // 隐藏身份证中间
      if (card.length > 15) {
        return card.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2');
      } else {
        return card.replace(/^(.{1})(?:\d+)(.{1})$/, '$1******$2');
      }
    }
  }
};