import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { getProject } from '@/utils/auth';
import logger from '@/utils/jspdf.debug';
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: getProject,
  name: function name(state) {
    return state.user.name;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  },
  customSetting: function customSetting(state) {
    return state.user.customSetting;
  },
  toolReviewRow: function toolReviewRow(state) {
    return state.toolReview.toolReviewRow;
  },
  isJianfa: function isJianfa(state) {
    var _state$config, _state$config$sp;

    return ((_state$config = state.config) === null || _state$config === void 0 ? void 0 : (_state$config$sp = _state$config.sp) === null || _state$config$sp === void 0 ? void 0 : _state$config$sp.company_id) === 2;
  },
  adminParameter: function adminParameter(state) {
    return state.user.adminParameter;
  },
  currentForm: function currentForm(state) {
    return state.rightSetting.currentForm;
  },
  subCurrentForm: function subCurrentForm(state) {
    return state.rightSetting.subCurrentForm;
  },
  currentConfing: function currentConfing(state) {
    return state.rightSetting.currentConfing;
  },
  afterCommontLIst: function afterCommontLIst(state) {
    return state.rightSetting.afterCommontLIst;
  },
  //开盘后配置
  startCommontList: function startCommontList(state) {
    return state.rightSetting.commontList;
  },
  //开盘前配置
  background: function background(state) {
    return state.rightSetting.background;
  },
  //背景色
  parentIndex: function parentIndex(state) {
    return state.rightSetting.parentIndex;
  },
  isChange: function isChange(state) {
    return state.rightSetting.isChange;
  },
  isHide: function isHide(state) {
    return state.rightSetting.isHide;
  },
  commontList: function commontList(state) {
    //画布显示
    var _state$user$adminPara = state.user.adminParameter,
        _state$user$adminPara2 = _state$user$adminPara.sp,
        cate_car_name = _state$user$adminPara2.cate_car_name,
        cate_room_name = _state$user$adminPara2.cate_room_name,
        _state$user$adminPara3 = _state$user$adminPara.list,
        cate_car = _state$user$adminPara3.cate_car,
        cate_room = _state$user$adminPara3.cate_room;
    var rightSetting = state.rightSetting;
    var _state$rightSetting = state.rightSetting,
        commontList = _state$rightSetting.commontList,
        defaultColor = _state$rightSetting.defaultColor,
        currentConfing = _state$rightSetting.currentConfing,
        afterCommontLIst = _state$rightSetting.afterCommontLIst;
    var mapvar = currentConfing == 1 ? commontList : afterCommontLIst;
    mapvar.map(function (item) {
      var styObj = {};

      if (item.type == 'time') {
        //时间组件
        for (var itemKey in rightSetting.timeConfing) {
          if (item.type2 != 'batchStart') {
            if (itemKey != 'timeJd_size' && itemKey != 'timeJd_Color') {
              styObj[itemKey] = styObj[itemKey] ? styObj[itemKey] : defaultColor[itemKey];
            }
          } else {
            styObj[itemKey] = styObj[itemKey] ? styObj[itemKey] : defaultColor[itemKey];
          }
        }

        item.styleForm = !item.styleForm ? styObj : item.styleForm;
      } else if (item.type == 'amount') {
        //容器组件===>有子组件
        for (var _itemKey in rightSetting.amountConfing) {
          if (_itemKey == 'background') {
            styObj[_itemKey] = defaultColor[_itemKey];
          }
        }

        item.children.map(function (child) {
          var styObj = {};

          for (var _itemKey2 in rightSetting.amountConfing) {
            if (_itemKey2 != 'background') {
              styObj[_itemKey2] = rightSetting.amountConfing[_itemKey2];
            }

            child.styleForm = !child.styleForm ? styObj : child.styleForm;

            if (cate_car && cate_room) {
              child.title = !child.title.includes('&') ? child.title.replace(/房源&/g, cate_room_name).replace(/车位&/g, cate_car_name) : child.title;
            } else {
              console.log(cate_car_name);
              child.title = !child.title.includes('&') ? child.title.replace(/房源&/g, cate_room ? cate_room_name : '').replace(/车位&/g, cate_car ? cate_car_name : '') : child.title;
            }
          }
        });
        item.styleForm = !item.styleForm ? styObj : item.styleForm;
      } else {
        var variable = item.type2;

        for (var _itemKey3 in rightSetting[variable + '_Confing']) {
          styObj[_itemKey3] = rightSetting[variable + '_Confing'][_itemKey3] || defaultColor[_itemKey3];
        }

        item.styleForm = !item.styleForm ? styObj : item.styleForm;
      }
    });
    return mapvar;
  }
};
export default getters;