import _objectSpread from "/www/wwwroot/bs/repository/project_24/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { buildDel, roomCateIndex, roomDel, roomSave, roomCateExport } from '@/api/openMaintenance';
import pdf from 'vue-pdf';
import { mapGetters } from 'vuex';
import GMainUpload from '../../components/my-upload';
import { adminParameter } from '@/api/public';
import kindEditor from '@/components/kindEditor';
import { validEmail } from '@/utils/validate';
import utils from '@/utils/utils';
import customSwitch from '@/components/customSwitch/index';
import { getpName } from '@/utils/auth';
import moment from 'moment';
export default {
  name: 'Graphic',
  components: {
    GMainUpload: GMainUpload,
    kindEditor: kindEditor,
    pdf: pdf,
    customSwitch: customSwitch
  },
  data: function data() {
    // 图片验证
    var checkImage = function checkImage(rule, value, callback) {
      if (value === '' || value === undefined || value === 'undefined') {
        return callback(new Error('图片不能为空'));
      } else {
        callback();
      }
    };

    var checkBachImg = function checkBachImg(rule, value, callback) {
      if (value === '' || value === undefined || value === 'undefined') {
        return callback(new Error('批量上传图片不能为空'));
      } else {
        callback();
      }
    };

    var checkSelect = function checkSelect(rule, value, callback) {
      if (value === '' || value === undefined || value === 'undefined') {
        return callback(new Error('请选择统计名称'));
      } else {
        callback();
      }
    };

    return {
      // 是否手机端
      isPC: utils.isPC(),
      //  统计名称
      room_build_type: [],
      car_build_type: [],
      buildBachRules: {
        image: [{
          required: true,
          validator: checkBachImg
        }]
      },
      BatchBuild: {
        type: '',
        image: '',
        typeImg: 2
      },
      // 批量修改楼栋区域图片
      isBatchBuild: false,
      buildRules: {
        name: [{
          required: true,
          message: '请输入户型名称',
          trigger: 'blur'
        }],
        type_id: [{
          required: true,
          validator: checkSelect
        }],
        image: [{
          required: true,
          validator: checkImage
        }]
      },
      // 新增&修改 户型  正则验证
      rules: {
        name: [{
          required: true,
          message: '请输入户型名称',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请输入户型结构',
          trigger: 'blur'
        }],
        tag: [{
          required: true,
          message: '至少一个型标签一个不用分号',
          trigger: 'blur'
        }] // image: [{ required: true, validator: checkImage }]

      },
      // 上传 tips
      uploadText: '请上传一份pdf格式的文件。',
      // 上传的 类型
      listType: 'text',
      listTypes: 'picture-card',
      //  活动介绍图
      ActivityList: [],
      // 操作指引图
      guideList: [],
      // pdf 图片
      pdfList: [],
      //  登录背景图片
      bgList: [],
      bgVideoList: [],
      // 背景视频
      //  获取 新增&修改户型 弹框
      room: {
        name: '',
        type: '',
        tag: '',
        image: ''
      },
      //  获取 新增&修改楼栋 弹框
      build: {
        name: '',
        type: '',
        image: '',
        typeImg: 2,
        type_id: 0
      },
      classStr: ['more-btn', 'success-btn', 'warning-btn'],
      // 去完善
      is_null: false,
      // 页面数据
      infoData: {},
      // 交互 提交的数据
      postData: {
        room_cate: [],
        build: [],
        project_images: {
          id: '',
          login_image: '',
          event_images: ''
        },
        project_notice: {
          id: '',
          name: '',
          notice_pdf: '',
          notice_text: '',
          content: '',
          project_tel: '',
          sign_prompt: '',
          sign_prompt_copy: 0
        }
      },
      // 户型资料 判断是别墅还是 住宅
      roomType: 1,
      // 楼栋资料 判断是别墅还是 住宅
      buildType: 1,
      // 楼栋弹框 title
      buildTitle: '',
      // 楼栋上传图片数据
      buildList: [],
      // 页面状态 新增还是 修改
      type: 'add',
      // 户型 弹框
      isUnit: false,
      // 楼栋弹框
      isBuild: false,
      // pdf 预览 弹框
      isPdf: false,
      // pdf  路径
      pdfUrl: '',
      // 修改用展示 图片数组 户型
      roomFileList: [],
      // 户型弹框 title
      unitTitle: '',
      // 判断户型 新增&修改
      roomOperate: 'add',
      // 判断楼栋 新增&修改
      buildOperate: 'add',
      // 打开预览bg 弹框
      isBg: false,
      // 登录图片
      project_images: {
        video_fill_status: 1
      },
      // 楼栋住宅
      buildResidence: '',
      // 别墅住宅
      roomResidence: '',
      // 户型资料  别墅
      roomVilla: [],
      // 楼栋 别墅
      buildVilla: [],
      project_notice: {},
      // 户型 loading
      unitLoading: false,
      // 公共权限
      adminList: {},
      // 楼栋 loading
      buildLoading: false,
      //   保存 loading
      saveLoading: false,
      // 别墅改车位
      newtype: 1,
      newtypeC: 1,
      isBatchBuildTitle: '',
      buildLoadingBatch: false,
      // 批量图片储存
      batchImg: {},
      roomResidenceIsShow: true,
      carResidenceIsShow: true,
      editDialog: false,
      radioType: 0
    };
  },
  computed: _objectSpread({}, mapGetters(['roles', 'customSetting'])),
  // 初始化
  created: function created() {
    var _this = this;

    if (this.roles.includes('roomcate/index') || this.roles.includes('admin')) {
      this.getRoomData();
    }

    adminParameter().then(function (res) {
      if (res.status === 200) {
        var list = res.data.list; // list.sale_census_build_type = 1

        _this.adminList = list;
      }
    });
  },
  methods: {
    showEditDialog: function showEditDialog() {
      this.editDialog = true;
    },
    handleSort: function handleSort(index, digit) {
      var list = this.radioType === 0 ? this.roomResidence : this.roomVilla; // 取值

      var c = list[index],
          n = list[index + digit];

      if (!n) {
        this.$message.error('没有可以交换的对象');
        return;
      } // 交换顺序


      var s = c.sort;
      c.sort = n.sort;
      n.sort = s; // 排序一下

      list.sort(function (a, b) {
        return a.sort - b.sort;
      });
      if (this.radioType === 0) this.roomResidence = list || [];
      if (this.radioType === 1) this.roomVilla = list;
    },
    funTextBuild: function funTextBuild(id) {
      var index = this.room_build_type.findIndex(function (e) {
        return e.id === id;
      });

      if (index === -1) {
        return '无';
      } else {
        return this.room_build_type[index].name;
      }
    },
    funText: function funText(id) {
      var index = this.car_build_type.findIndex(function (e) {
        return e.id === id;
      });

      if (index === -1) {
        return '无';
      } else {
        console.log(this.car_build_type[index].name);
        return this.car_build_type[index].name;
      }
    },
    handleSaveBatchBuild: function handleSaveBatchBuild() {
      var _this2 = this;

      this.$refs['BatchBuild'].validate(function (valid) {
        if (valid) {
          var key = '';

          switch (_this2.batchType) {
            case 1:
              key = 'buildResidence';
              break;

            case 2:
              key = 'buildVilla';
              break;

            case 3:
              key = 'roomResidence';
              break;

            case 4:
              key = 'roomVilla';
              break;
          }

          _this2[key].forEach(function (item) {
            item.image = _this2.batchImg.image;
            item.more_image = _this2.batchImg.more_image;
          });

          _this2.isBatchBuild = false;

          _this2.handleSave(1);
        }
      });
    },
    handleBatchBuildSuccess: function handleBatchBuildSuccess(url) {
      this.buildBachRules.image = url.join(',');
      this.batchImg.image = url[0];
      var more_image = [];

      if (url.length > 1) {
        for (var i = 1; i < url.length; i++) {
          more_image.push(url[i]);
        }
      }

      this.batchImg.more_image = more_image.join(',');
    },
    handleBatchBuild: function handleBatchBuild(type) {
      this.buildLoadingBatch = type;
    },
    // 批量替换图片
    handleBatchImg: function handleBatchImg(type) {
      if (type === 1) {
        this.isBatchBuildTitle = '楼栋资料';
      } else if (type === 2) {
        this.isBatchBuildTitle = '区域资料';
      } else if (type === 3) {
        this.isBatchBuildTitle = '户型';
      } else if (type === 4) {
        this.isBatchBuildTitle = '类型';
      }

      this.batchType = type;
      this.batchImg = {};
      this.isBatchBuild = true;
    },
    // 批量显示隐藏
    onRoomResidenceIsShow: function onRoomResidenceIsShow(value) {
      this.roomResidenceIsShow = !value;
      var roomResidence = JSON.parse(JSON.stringify(this.roomResidence));

      for (var i = 0; i < roomResidence.length; i++) {
        roomResidence[i].is_show = this.roomResidenceIsShow ? 0 : 1;
      }

      this.roomResidence = roomResidence || [];
    },
    // 批量显示隐藏
    onCarResidenceIsShow: function onCarResidenceIsShow(value) {
      this.carResidenceIsShow = !value;
      var roomVilla = JSON.parse(JSON.stringify(this.roomVilla));

      for (var i = 0; i < roomVilla.length; i++) {
        roomVilla[i].is_show = this.carResidenceIsShow ? 0 : 1;
      }

      this.roomVilla = roomVilla;
    },
    // 房源显示隐藏
    onItemShow: function onItemShow(item, index) {
      this.roomResidence[index].is_show = item.is_show === 1 ? 0 : 1;
      this.roomShow();
    },
    // 批量显示隐藏 回显
    roomShow: function roomShow() {
      var all = 0; // 默认都显示

      for (var i = 0; i < this.roomResidence.length; i++) {
        if (!this.roomResidence[i].is_show) all++; // 统计隐藏
      } // 隐藏数量跟总数一致


      this.roomResidenceIsShow = all === this.roomResidence.length;
    },
    // 车位显示隐藏
    onCardItemShow: function onCardItemShow(item, index) {
      this.roomVilla[index].is_show = item.is_show === 1 ? 0 : 1;
      this.carShow();
    },
    // 车位
    carShow: function carShow() {
      var all = 0; // 默认都显示

      for (var i = 0; i < this.roomVilla.length; i++) {
        if (!this.roomVilla[i].is_show) all++; // 统计隐藏
      } // 隐藏数量跟总数一致


      this.carResidenceIsShow = all === this.roomVilla.length;
    },
    // 项目图片  背景图片
    handleSaveImg: function handleSaveImg(type) {
      this.saveLoading = type;
    },
    // 楼栋 loading
    handleBuildOperation: function handleBuildOperation(type) {
      this.buildLoading = type;
    },
    // 户型loading
    handOperation: function handOperation(type) {
      this.unitLoading = type;
    },
    // 登录背景图片预览
    handlePreview: function handlePreview() {
      this.isBg = true;
    },
    // 打开修改楼栋弹框
    handleEditBuild: function handleEditBuild(type, i) {
      this.newtypeC = type;

      if (type == 1) {
        this.buildTitle = '修改楼栋';
        var data = this.buildResidence[i]; // 回显图片

        this.buildList = data.image === '' ? [] : [{
          url: this.imgUrl + data.image
        }]; // 回显数据

        this.build = {
          id: data.id,
          name: data.name,
          type: data.type,
          image: data.image,
          typeImg: data.image ? 2 : 1,
          type_id: data.type_id === 0 ? '' : data.type_id
        };
      } else {
        this.buildTitle = '修改区域';
        var _data = this.buildVilla[i]; // 回显图片

        this.buildList = _data.image === '' ? [] : [{
          url: this.imgUrl + _data.image
        }]; // 回显数据

        this.build = {
          id: _data.id,
          name: _data.name,
          type: _data.type,
          image: _data.image,
          typeImg: _data.image ? 2 : 1,
          type_id: _data.type_id === 0 ? '' : _data.type_id
        };
      } //  判断是 别墅 还是 住宅


      this.buildType = type; // 修改弹框

      this.buildOperate = 'edit'; // 打开弹框

      this.isBuild = true; //

      this.buildIndex = i;
    },
    // 关闭 户型 楼栋 弹框
    handelClose: function handelClose() {
      this.roomFileList = [];
      this.isUnit = false;
      this.isBuild = false;
      this.buildList = [];
      this.build = {
        typeImg: 1
      };
      this.room = {};
    },
    //  pdf 绑定元素
    handlePdf: function handlePdf() {
      this.isPdf = true;
      this.pdfUrl = this.imgUrl + this.infoData.project_notice.notice_pdf;
    },
    // 删除楼栋
    handleDelBuild: function handleDelBuild(type, i) {
      var _this3 = this;

      var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        // id 等于空 刚才新建的，没有id,直接删除;
        if (id == '') {
          if (type === 1) {
            _this3.buildResidence.splice(i, 1);
          } else {
            _this3.buildVilla.splice(i, 1);
          }

          _this3.$message({
            message: '删除成功',
            type: 'success'
          });

          return;
        }

        buildDel({
          id: id
        }).then(function (res) {
          if (type === 1) {
            _this3.buildResidence.splice(i, 1);
          } else {
            _this3.buildVilla.splice(i, 1);
          }

          _this3.$message({
            message: res.data.msg,
            type: 'success'
          });
        });
      }).catch(function (err) {});
    },
    // 修改户型
    handleEditRoom: function handleEditRoom(type, i) {
      var _this4 = this;

      if (type == 1) {
        this.unitTitle = '修改户型';
        var data = this.roomResidence[i];
        var img = '';

        if (data.more_image == '') {
          img = data.image;
        } else {
          img = data.image + ',' + data.more_image;
        } // 回显图片


        if (img === '') {
          this.roomFileList = [];
        } else {
          img.split(',').forEach(function (item) {
            var obj = {};
            obj.url = _this4.imgUrl + item;

            _this4.roomFileList.push(obj);
          });
        } // 回显数据


        this.room = {
          id: data.id,
          name: data.name,
          type: data.type,
          tag: data.tag,
          image: data.image
        };
      } else {
        this.unitTitle = '修改类型';
        var _data2 = this.roomVilla[i];
        var _img = '';

        if (_data2.more_image == '') {
          _img = _data2.image;
        } else {
          _img = _data2.image + ',' + _data2.more_image;
        } // 回显图片


        if (_img === '') {
          this.roomFileList = [];
        } else {
          _img.split(',').forEach(function (item) {
            var obj = {};
            obj.url = _this4.imgUrl + item;

            _this4.roomFileList.push(obj);
          });
        } // 回显数据


        this.room = {
          id: _data2.id,
          name: _data2.name,
          type: _data2.type,
          tag: _data2.tag,
          image: _data2.image
        };
      } //  判断是 别墅 还是 住宅


      this.roomType = type;
      this.newtype = type; // 修改弹框

      this.roomOperate = 'edit'; // 打开弹框

      this.isUnit = true;
      this.roomIndex = i;
    },
    // 删除户型
    handleDelRoom: function handleDelRoom(type, i) {
      var _this5 = this;

      var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      this.$confirm('此操作不可复原，确认删除吗？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        // id 等于空 刚才新建的，没有id,直接删除;
        if (id === '') {
          if (type === 1) {
            _this5.roomResidence.splice(i, 1);
          } else {
            _this5.roomVilla.splice(i, 1);
          }

          _this5.$message({
            message: '删除成功',
            type: 'success'
          });

          return;
        }

        roomDel({
          id: id
        }).then(function (res) {
          if (type === 1) {
            _this5.roomResidence.splice(i, 1);
          } else {
            _this5.roomVilla.splice(i, 1);
          }

          _this5.$message({
            message: res.data.msg,
            type: 'success'
          });
        });
      }).catch(function (err) {});
    },
    // 点击去修改
    handleEdit: function handleEdit() {
      this.type = 'add';
    },
    // 获取 pdf 文件路径
    handlePdfSuccess: function handlePdfSuccess(url) {
      this.infoData.project_notice.notice_pdf = url.join(',');
    },
    // 获取 活动介绍图片
    handleActivitySuccess: function handleActivitySuccess(url) {
      this.infoData.project_images.event_images = url.join(',');
    },
    // 获取 操作指引图
    handleGuideSuccess: function handleGuideSuccess(url) {
      this.infoData.project_images.guide = url.join(',');
    },
    // 获取登录背景图片
    handleBgSuccess: function handleBgSuccess(url) {
      this.infoData.project_images.login_image = url.join(',');
    },
    // 上传成功 文件
    handleVideoFileSuccess: function handleVideoFileSuccess(url) {
      this.project_images.index_video = url.join(',');
    },
    // 获取 新增楼栋的图片
    handleBuildImg: function handleBuildImg(url) {
      this.build.image = url.join(',');
    },
    // 保存 楼栋资料
    handleSaveBuild: function handleSaveBuild() {
      var _this6 = this;

      this.$refs['build'].validate(function (valid) {
        if (valid) {
          if (_this6.buildOperate === 'add') {
            if (_this6.buildType === 1) {
              _this6.build.cate = 1; //  改变页面数据

              _this6.buildResidence.push(_this6.build);

              _this6.handleSave(1);
            } else {
              _this6.build.cate = 2; //  改变页面数据

              _this6.buildVilla.push(_this6.build);

              _this6.handleSave(1);
            }
          } else {
            // 修改数据
            _this6.build.type_id = _this6.build.type_id == '' ? 0 : _this6.build.type_id;

            if (_this6.buildType === 1) {
              _this6.build.cate = 1;

              if (_this6.build.typeImg === 1) {
                _this6.build.image = '';
              }

              _this6.buildResidence[_this6.buildIndex] = _this6.build;
            } else {
              _this6.build.cate = 2;

              if (_this6.build.typeImg === 1) {
                _this6.build.image = '';
              }

              _this6.buildVilla[_this6.buildIndex] = _this6.build;
            } // 保存接口


            _this6.handleSave(1);
          } // 关闭弹窗


          _this6.isBuild = false; //  清空 img

          _this6.buildList = []; // 清空 build

          _this6.build = {
            name: '',
            type: '',
            tag: '',
            typeImg: '1',
            image: '',
            type_id: 0
          };
        }
      });
    },
    // 修改添加 楼栋 弹框
    handleBuilding: function handleBuilding(type) {
      this.newtypeC = type;

      if (type === 1) {
        // 住宅
        this.buildTitle = '增加楼栋';
      } else {
        this.buildTitle = '增加区域';
      }

      this.build.typeImg = 2;
      this.buildType = type;
      this.isBuild = true;
    },
    // 获取 户型提交的图片
    handleRoomImg: function handleRoomImg(url) {
      this.room.image = url[0];
      console.log(url);
      var more_image = [];

      if (url.length > 1) {
        for (var i = 1; i < url.length; i++) {
          more_image.push(url[i]);
        }
      }

      this.room.more_image = more_image.join(',');
    },
    // 保存 户型资料
    handleSaveRoom: function handleSaveRoom(formName) {
      var _this7 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // if (this.room.image === '') {
          //   this.$message({
          //     message: '请上传图片',
          //     type: 'warning'
          //   })
          //
          //   return
          // }
          // 新增  block
          if (_this7.roomOperate === 'add') {
            // 住宅区
            if (_this7.roomType === 1) {
              _this7.room.cate = 1; //  改变页面数据

              _this7.roomResidence.push(_this7.room);
            } else {
              // 别墅区
              _this7.room.cate = 2; //  改变页面数据

              _this7.roomVilla.push(_this7.room);
            } // 保存接口


            _this7.handleSave(1);
          } else {
            // 修改数据
            if (_this7.roomType === 1) {
              _this7.room.cate = 1;
              _this7.roomResidence[_this7.roomIndex] = _this7.room;
            } else {
              _this7.room.cate = 2;
              _this7.roomVilla[_this7.roomIndex] = _this7.room;
            } // 保存接口


            _this7.handleSave(1);
          } // 关闭弹窗


          _this7.isUnit = false; //  清空 img

          _this7.roomFileList = []; // 清空 room

          _this7.room = {
            name: '',
            type: '',
            tag: '',
            image: ''
          };
        }
      });
    },
    // 去完善
    handlePerfect: function handlePerfect() {
      this.is_null = false;
      this.type = 'add';
    },
    // 获取图文资料数据
    getRoomData: function getRoomData(type) {
      var _this8 = this;

      roomCateIndex().then(function (res) {
        var _list$project_images;

        var list = res.data.list; // 是否完善

        _this8.is_null = list.is_null; //  等于false 页面就是修改

        if (type !== 1) {
          if (!_this8.is_null) {
            _this8.type = 'edit';
          }
        }

        if (!list.project_images.event_images) {
          list.project_images.event_images = '';
          _this8.ActivityList = [];
        }

        if (!list.project_images.guide) {
          list.project_images.guide = '';
          _this8.guideList = [];
        }

        if (!list.project_images.login_image) {
          list.project_images.login_image = '';
          _this8.bgList = [];
        } // 活动介绍图 回显


        if (list.project_images.event_images !== '' && JSON.stringify(list.project_images) !== '{}') {
          list.project_images.event_images.split(',').forEach(function (item) {
            _this8.ActivityList.push({
              url: _this8.imgUrl + item
            });
          });
        } // 操作指引图 回显


        if (list.project_images.guide !== '' && JSON.stringify(list.project_images) !== '{}') {
          list.project_images.guide.split(',').forEach(function (item) {
            _this8.guideList.push({
              url: _this8.imgUrl + item
            });
          });
        } // 登录背景图片


        if (list.project_images.login_image !== '' && JSON.stringify(list.project_images) !== '{}') {
          list.project_images.login_image.split(',').forEach(function (item) {
            _this8.bgList.push({
              url: _this8.imgUrl + item
            });
          });
        }

        if (list !== null && list !== void 0 && (_list$project_images = list.project_images) !== null && _list$project_images !== void 0 && _list$project_images.index_video) {
          list.project_images.index_video.split(',').forEach(function (item) {
            _this8.bgVideoList.push({
              url: _this8.imgUrl + item,
              name: '视频文件'
            });
          });
        }

        _this8.project_images = list.project_images; // 活动数据

        _this8.project_notice = list.project_notice; // 页面数据

        _this8.infoData = list; //  户型住宅

        _this8.buildResidence = list.bulid.residence || []; //  楼栋住宅

        _this8.roomResidence = list.room_cate.residence || []; // 户型别墅

        _this8.buildVilla = list.bulid.villa || []; // 楼栋别墅

        _this8.roomVilla = list.room_cate.villa || []; // 车位

        _this8.car_build_type = list.car_build_type; // 住宅

        _this8.room_build_type = list.room_build_type;
        _this8.radioType = _this8.infoData.cate_room === 1 ? 0 : _this8.customSetting.cate_villa === 1 || _this8.customSetting.cate_shops === 1 || _this8.customSetting.cate_car === 1 ? 1 : 3;

        _this8.roomShow();

        _this8.carShow();
      });
    },
    //  添加 住宅  // type类型   operate 操作
    handleUnitHouse: function handleUnitHouse(type) {
      this.newtype = type;

      if (type === 1) {
        this.unitTitle = '增加户型';
      } else {
        this.unitTitle = '增加' + this.customSetting.villa_setting.house_type;
      } // 住宅还是 别墅


      this.roomType = type; // 打开弹框

      this.isUnit = true; //  add

      this.roomOperate = 'add'; // 清空 image

      this.roomFileList = [];
    },
    // 提交全部数据
    handleSave: function handleSave(type) {
      var _this$$refs$kindEdito,
          _this$$refs$kinConten,
          _this$$refs$kinConten2,
          _this$$refs$kinConten3,
          _this9 = this;

      //  loading
      var loading = this.$loading({
        lock: true,
        text: '努力保存中···',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // 获取活动须知正文内容

      this.infoData.project_notice.notice_text = (_this$$refs$kindEdito = this.$refs['kindEditor']) === null || _this$$refs$kindEdito === void 0 ? void 0 : _this$$refs$kindEdito.outContent;
      this.infoData.project_notice.content = (_this$$refs$kinConten = this.$refs['kinContent']) === null || _this$$refs$kinConten === void 0 ? void 0 : _this$$refs$kinConten.outContent;
      this.infoData.project_notice.buy_prompt = (_this$$refs$kinConten2 = this.$refs['kinContentBuy']) === null || _this$$refs$kinConten2 === void 0 ? void 0 : _this$$refs$kinConten2.outContent;
      this.infoData.project_notice.sign_prompt = (_this$$refs$kinConten3 = this.$refs['kinContents']) === null || _this$$refs$kinConten3 === void 0 ? void 0 : _this$$refs$kinConten3.outContent;
      this.infoData.project_notice.sign_prompt_copy = this.project_notice.sign_prompt_copy; // if (this.infoData.project_notice.buy_prompt.length > 100) {
      //     this.$message.error('客户端认购提示语字符长度超出上限100')
      //     loading.close()
      //     return
      // }

      this.postData = {
        room_cate: this.roomResidence.concat(this.roomVilla),
        build: this.buildResidence.concat(this.buildVilla),
        project_images: this.infoData.project_images,
        project_notice: this.infoData.project_notice
      };
      roomSave(this.postData).then(function (res) {
        var msg = res.data.msg;

        if (res.status === 200) {
          _this9.$message({
            message: msg,
            type: 'success'
          });

          if (type !== 1) {
            _this9.type = 'edit';
          } else {
            _this9.getRoomData(1);

            _this9.bgList = [];
            _this9.bgVideoList = [];
            _this9.ActivityList = [];
          }
        }

        loading.close();
      }).catch(function () {
        loading.close();
      });
    },
    // 字符串替换
    getString: function getString(str) {
      return str.replace(new RegExp(this.imgUrl, 'g'), '');
    },
    // 导出复核
    handleExport: function handleExport() {
      var _this10 = this;

      var loading = this.$loading({
        lock: true,
        text: '导出中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      roomCateExport().then(function (res) {
        if (res.status === 200) {
          _this10.$message({
            type: 'success',
            message: '导出成功'
          }); // 下载
          // window.location.href = this.host + res.data.path


          utils.download_files_base64(res.data.data, res.data.name, res.data.suffix);
        }
      }).finally(function (res) {
        loading.close();
      });
    }
  }
};