var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", staticClass: "container" },
    [
      _c(
        "div",
        { ref: "top", staticClass: "page-header-wrapper" },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c("div", { staticClass: "page-header" }, [
                _c(
                  "div",
                  { staticClass: "page-header-left" },
                  [
                    _c("event-select", {
                      attrs: { "event-list": _vm.eventList },
                      on: { select_round: _vm.selectRoundHandle },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "page-header-right" }, [
                  _c(
                    "div",
                    { staticClass: "operate" },
                    [
                      _vm.is_radio
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { "max-width": "80px" },
                              attrs: { placeholder: "请选择", size: "small" },
                              on: { change: _vm.handleCate },
                              model: {
                                value: _vm.from.room_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.from, "room_type", $$v)
                                },
                                expression: "from.room_type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: 0 },
                              }),
                              _c("el-option", {
                                attrs: { label: "房源", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "车位", value: 2 },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-input", {
                        staticClass: "input-flex",
                        staticStyle: {
                          "margin-left": "10px",
                          "max-width": "230px",
                        },
                        attrs: {
                          size: "small",
                          placeholder:
                            _vm.from.room_type == 2
                              ? "姓名\\手机\\订单号\\" +
                                _vm.$adminList.user_code_name +
                                "\\" +
                                _vm.customSetting.transaction_records.house_num
                              : "姓名\\手机\\订单号\\" +
                                _vm.$adminList.user_code_name +
                                "\\房号",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleSearch($event)
                          },
                        },
                        model: {
                          value: _vm.from.no,
                          callback: function ($$v) {
                            _vm.$set(_vm.from, "no", $$v)
                          },
                          expression: "from.no",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "operate mL10" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "greens-btn",
                              attrs: { size: "small" },
                              on: { click: _vm.handleSearch },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btnHollowReset",
                              attrs: { size: "small" },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "more",
                              on: { click: _vm.bindMore },
                            },
                            [
                              _vm._v(_vm._s(_vm.moreText) + " "),
                              _c("i", { class: _vm.moreIcon }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm.is_more
                ? _c(
                    "el-row",
                    { staticClass: "is_more", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "订单状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    size: "small",
                                  },
                                  model: {
                                    value: _vm.from.order_status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.from, "order_status", $$v)
                                    },
                                    expression: "from.order_status",
                                  },
                                },
                                _vm._l(_vm.orderStatusOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.label,
                                      value: item.value.toString(),
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "签约状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.from.handled,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.from, "handled", $$v)
                                    },
                                    expression: "from.handled",
                                  },
                                },
                                _vm._l(_vm.onLine, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      value: item.value,
                                      label: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "作废状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.from.invalidstatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.from, "invalidstatus", $$v)
                                    },
                                    expression: "from.invalidstatus",
                                  },
                                },
                                _vm._l(
                                  _vm.Invalidstatus,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        value: item.value,
                                        label: item.label,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "存档状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "small",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.from.is_archive,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.from, "is_archive", $$v)
                                    },
                                    expression: "from.is_archive",
                                  },
                                },
                                _vm._l(_vm.archived, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      value: item.value,
                                      label: item.label,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { xs: 20, sm: 12, md: 8, lg: 6 } },
                        [
                          _c("operator-user", {
                            attrs: { type: "form-item" },
                            model: {
                              value: _vm.from.admin_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.from, "admin_id", $$v)
                              },
                              expression: "from.admin_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "block-bottom mT16" }, [
        _c("div", { staticClass: "button-box" }, [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["admin", "sign/getcontract", "sign/delall"],
                      expression:
                        "['admin', 'sign/getcontract', 'sign/delall']",
                    },
                  ],
                  staticClass: "mR10",
                  attrs: { size: "small" },
                  on: { command: _vm.handleCommand },
                },
                [
                  _c(
                    "el-button",
                    { staticClass: "greens-btn", attrs: { size: "small" } },
                    [
                      _vm._v(" 批量操作 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _vm.sign_up_type_status === 1
                        ? _c(
                            "el-dropdown-item",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["admin", "sign/getcontract"],
                                  expression: "['admin', 'sign/getcontract']",
                                },
                              ],
                              staticStyle: { width: "120px" },
                              attrs: { command: "handelDownload" },
                            },
                            [_vm._v("批量下载")]
                          )
                        : _vm._e(),
                      _c(
                        "el-dropdown-item",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["admin", "sign/delall"],
                              expression: "['admin', 'sign/delall']",
                            },
                          ],
                          staticStyle: { width: "120px" },
                          attrs: { command: "delAll" },
                        },
                        [_vm._v("批量删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("id-card-drive", { ref: "cardDrive" }),
            ],
            1
          ),
          _c("div", { staticClass: "flex-space-between" }, [
            _c("div", { staticClass: "checked-num" }),
            _c(
              "div",
              { staticClass: "right-wrapper" },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permission",
                        rawName: "v-permission",
                        value: ["admin", "sign/export"],
                        expression: "['admin', 'sign/export']",
                      },
                    ],
                    staticClass: "btnHollowGreen",
                    attrs: { size: "small" },
                    on: { click: _vm.handelExport },
                  },
                  [_vm._v("导出数据 ")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tableLoading,
                    expression: "tableLoading",
                  },
                ],
                attrs: {
                  border: "",
                  "highlight-current-row": "",
                  size: "medium",
                  data: _vm.tableData,
                  height: _vm.tableHeight,
                  "header-cell-style": { background: "#fafafa" },
                  "element-loading-background": "rgba(0, 0, 0, 0.5)",
                  "element-loading-text": "数据正在加载中",
                  "element-loading-spinner": "el-icon-loading",
                },
                on: {
                  "sort-change": _vm.sortChange,
                  "selection-change": _vm.handleSelectionChange,
                },
              },
              [
                _c("el-table-column", {
                  attrs: { align: "center", type: "selection", width: "40" },
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    label: "序号",
                    align: "center",
                    width: "50",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "订单号", width: "200" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.order_sn))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "140",
                    align: "center",
                    prop: "room",
                    label:
                      _vm.from.room_type == 2
                        ? _vm.customSetting.transaction_records.house_num
                        : _vm.from.room_type === 1
                        ? "房号"
                        : "房源&" +
                          _vm.customSetting.transaction_records.house_num,
                    sortable: "custom",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "btn-text",
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.toRoomDetail(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.room))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "类型", width: "50" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          scoped.row.cate === 1
                            ? _c("span", [_vm._v("房源")])
                            : scoped.row.cate === 2
                            ? _c("span", [_vm._v("车位")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "name",
                    label: "客户姓名",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "btn-text",
                              attrs: { size: "small", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.toUserDeatil(scope.row.user_id)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.name))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "tel",
                    label: "登录手机号",
                    width: "110",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "code",
                    label: _vm.$adminList.user_code_name,
                    width: "120",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "签约方式", width: "100" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          scoped.row.type === 1
                            ? _c("span", [_vm._v("线上签约")])
                            : _c("span", [_vm._v("线下签约")]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "签约状态" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          scoped.row.handled === -1
                            ? _c("span", [_vm._v("已作废")])
                            : scoped.row.handled === 0
                            ? _c("span", [_vm._v("待签约")])
                            : scoped.row.handled === 2
                            ? _c("span", [_vm._v("签约完毕")])
                            : scoped.row.handled === 1
                            ? _c("span", [_vm._v("签约中")])
                            : _c("span", [_vm._v("待签约")]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    width: "150",
                    label:
                      _vm.adminList.sign_up_type === 1
                        ? "发起签约时间"
                        : "打印认购书时间",
                    prop: "or_time",
                    sortable: "custom",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          scoped.row.is_printing === 2
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        scoped.row.printing_time * 1000
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("dateFormat")(
                                        scoped.row.created_at * 1000
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "150",
                    label: "签约完成时间",
                    prop: "sign_time",
                    sortable: "custom",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: scoped.row.handled === 2,
                                  expression: "scoped.row.handled === 2",
                                },
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      scoped.row.sign_time * 1000
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "150",
                    label: "作废时间",
                    prop: "invalid_time",
                    sortable: "custom",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    scoped.row.invalid_time * 1000
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm.adminList.data_file === 1
                  ? _c("el-table-column", {
                      key: "archive_log_id",
                      attrs: {
                        align: "center",
                        width: "150",
                        label: "存档状态",
                        prop: "archive_log_id",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scoped) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scoped.row.archive_log_id
                                        ? "已存档"
                                        : "未存档"
                                    ) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1300251243
                      ),
                    })
                  : _vm._e(),
                _c("el-table-column", {
                  attrs: {
                    prop: "address",
                    fixed: "right",
                    label: "操作",
                    align: "center",
                    width: "140",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scoped) {
                        return [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "sign/cancel"],
                                      expression: "['admin', 'sign/cancel']",
                                    },
                                  ],
                                  attrs: { xs: 20, sm: 12, md: 8, lg: 6 },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "作废",
                                        placement: "top-end",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: {
                                          "class-name": "greens",
                                          "icon-class": "zuofei",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleVoid(scoped.row)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "sign/getcontract"],
                                      expression:
                                        "['admin', 'sign/getcontract']",
                                    },
                                  ],
                                  attrs: { xs: 20, sm: 12, md: 8, lg: 6 },
                                },
                                [
                                  scoped.row.type === 1
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "查看电子合同",
                                            placement: "top-end",
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "greens",
                                              "icon-class": "dzorder",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleInfo(
                                                  scoped.row.id,
                                                  scoped.row
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticStyle: { opacity: "0" } },
                                        [_vm._v("11")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "sign/archive"],
                                      expression: "['admin', 'sign/archive']",
                                    },
                                  ],
                                  attrs: { xs: 20, sm: 12, md: 8, lg: 6 },
                                },
                                [
                                  _vm.adminList.data_file === 1 &&
                                  !scoped.row.archive_log_id
                                    ? _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            content: "资料存档",
                                            effect: "dark",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "greens",
                                              "icon-class": "SignIn",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onArchive(scoped.row)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticStyle: { opacity: "0" } },
                                        [_vm._v("11")]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "permission",
                                      rawName: "v-permission",
                                      value: ["admin", "sign/getvideo"],
                                      expression: "['admin', 'sign/getvideo']",
                                    },
                                  ],
                                  attrs: { xs: 20, sm: 12, md: 8, lg: 6 },
                                },
                                [
                                  _vm.adminList.print_video &&
                                  scoped.row.video_url
                                    ? _c(
                                        "el-tooltip",
                                        { attrs: { content: "视频预览" } },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "class-name": "greens",
                                              "icon-class": "video-preview",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openVideo(scoped.row)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        { staticStyle: { opacity: "0" } },
                                        [_vm._v(".")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pagination" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.currentPage,
                "page-sizes": _vm.sizes,
                "page-size": 10,
                layout: _vm.layouts,
                total: _vm.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "user-list-container",
          attrs: {
            width: "800px",
            top: "10vh",
            visible: _vm.dialogConfig.visible1,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogConfig, "visible1", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "user-list-popup" }, [
            _c("div", { staticClass: "popup-hander" }, [
              _c("div", { staticClass: "search" }, [
                _c("img", {
                  staticClass: "search_icon",
                  attrs: { src: require("../../assets/image/suc.png") },
                }),
                _c("span", { staticClass: "title" }, [
                  _vm._v("找到" + _vm._s(_vm.userList.length || 0) + "个客户"),
                ]),
              ]),
              _c("div", { staticClass: "checke-user-number" }, [
                _vm._v("已选" + _vm._s(_vm.ckeckNumber) + "个"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "popup-container" },
              _vm._l(_vm.userList, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "popup-content-item",
                    on: {
                      click: function ($event) {
                        return _vm.selectUser(item)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "checked",
                          {
                            "current-checked": item.checked,
                            checked_disabled: !item.succeed,
                          },
                        ],
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.checked,
                              expression: "item.checked",
                            },
                          ],
                          attrs: {
                            src: require("../../assets/image/check.png"),
                            alt: "勾选按钮",
                          },
                        }),
                      ]
                    ),
                    item.tips
                      ? _c("div", { staticClass: "signet" }, [
                          item.succeed
                            ? _c("img", {
                                attrs: {
                                  src: require("../../assets/image/signet_green.png"),
                                },
                              })
                            : item.error
                            ? _c("img", {
                                attrs: {
                                  src: require("../../assets/image/signet_red.png"),
                                },
                              })
                            : item.repetition
                            ? _c("img", {
                                attrs: {
                                  src: require("../../assets/image/signet_yellow.png"),
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "signet_tips",
                              style: {
                                color: item.succeed
                                  ? "#22A271"
                                  : item.error
                                  ? "#FF3131"
                                  : "#FFBF00",
                              },
                            },
                            [_vm._v(" " + _vm._s(item.tips) + " ")]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", [
                      _c("div", { staticClass: "user-info-top" }, [
                        _c("div", { staticClass: "avatar-container" }, [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: require("../../assets/image/avatar_icon.png"),
                            },
                          }),
                        ]),
                        _c("div", [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.name) + " "),
                            item.rand_name
                              ? _c("span", { staticClass: "rend_tag" }, [
                                  _vm._v(_vm._s(item.rand_name)),
                                ])
                              : _vm._e(),
                          ]),
                          item.code
                            ? _c("div", { staticClass: "user-code" }, [
                                _vm._v(
                                  _vm._s(_vm.$adminList.user_code_name) +
                                    "：" +
                                    _vm._s(item.code)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "user-info-bottom" },
                        [
                          _c("span", [
                            _vm._v(" 手机号：" + _vm._s(_vm.iphone(item.tel))),
                          ]),
                          item.admin_name
                            ? _c("div", [
                                _vm._v(
                                  "置业顾问：" +
                                    _vm._s(item.admin_name) +
                                    " " +
                                    _vm._s(
                                      item.t_name ? "(" + item.t_name + ")" : ""
                                    )
                                ),
                              ])
                            : _vm._e(),
                          item.uao_list
                            ? _vm._l(item.uao_list, function (i, j) {
                                return _c("div", { key: j }, [
                                  _vm._v(
                                    "产权人" +
                                      _vm._s(j + 1) +
                                      "：" +
                                      _vm._s(i.name) +
                                      " " +
                                      _vm._s(
                                        i.card
                                          ? "(" + _vm.card(i.card) + ")"
                                          : ""
                                      )
                                  ),
                                ])
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "popup-footer" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "definite_entry",
                    attrs: { type: "primary", disabled: _vm.ckeckNumber == 0 },
                    on: { click: _vm.dialogConfirm },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "user-error-container",
          attrs: {
            width: "1205px",
            top: "10vh",
            visible: _vm.dialogConfig.visible2,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialogConfig, "visible2", $event)
            },
          },
        },
        [
          _c("div", { staticClass: "popup-err-container" }, [
            _c("img", {
              staticClass: "error_bg",
              attrs: { src: require("../../assets/image/sign_in_err.png") },
            }),
            _c("div", { staticClass: "popup-top" }, [
              _c("img", {
                staticClass: "error_icon",
                attrs: { src: require("../../assets/image/Sigh.png") },
              }),
              _c("span", [_vm._v("未找到客户")]),
            ]),
            _c("div", { staticClass: "hint" }, [
              _vm._v("该客户未录入系统，请确认客户信息后重新入场！"),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { width: "800px", visible: _vm.previewVideo },
          on: {
            "update:visible": function ($event) {
              _vm.previewVideo = $event
            },
            close: _vm.closeVideo,
          },
        },
        [
          _c("video", {
            ref: "videoPreview",
            staticStyle: { width: "100%", height: "100%" },
            attrs: { controls: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }